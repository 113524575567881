import { useState } from 'react';
import { TextField } from '@material-ui/core';

// TODO: Add validation to device name char length
const ModalEditTBDevice = ({
  tbDevice = { deviceName: '' },
}) => {
  const [name, setName] = useState(tbDevice.deviceName);

  const handleNameChange = (e) => {
    setName(e.target.value);
    tbDevice.deviceName = e.target.value;
  };

  return (
    <TextField
      required
      fullWidth
      autoFocus
      value={name}
      onChange={handleNameChange}
      label="Device Name"
    />
  );
};

export default ModalEditTBDevice;