import React, { useEffect } from 'react';
import Unauthorized from './pages/Unauthorized';
import Landing from './pages/Landing';
import Sidebar from './components/Sidebar';
import UsersList from './pages/UsersList';
import UserDetail from './pages/UserDetail';
import EmployeesList from './pages/EmployeesList';
import Auth from './utils/mock-auth';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import s from './App.module.scss';

// TODO: all stylings have been targetted for 1200 x 760ish sreen
    // Other screen sizes will possibly need styling adjustments
  // Modals - may need some style adjustments
    // The modal's otherwise should all be functional, just add api calls
  // Optional - improve asset importing, quality of assets
  // Optional - cleaner if notification & modal component is refactored to this level
const AppBody = () => {
  const user = Auth.getUser();
  const hasRole = (roles) => roles.includes(user.getUserRole());

  if (!user.loggedIn()) {
    return <Landing />;
  } else if (!hasRole(['admin', 'member', 'viewer'])) {
    return <Unauthorized />;
  }

  const isUpdatable = (Component, roles) => (
    () => <Component isUpdatable={hasRole(roles)} />
  );

  return (
    <div className={s.AppBody}>
      <Router>
        <Sidebar user={user} />
        <div className={s.pageContent}>
          <Switch>
            <Redirect exact from="/" to="/users" />
            <Route exact path="/users" component={isUpdatable(UsersList, ['admin', 'member'])} />
            <Route exact path="/users/:id" component={isUpdatable(UserDetail, ['admin', 'member'])} />
            <Route exact path="/employees" component={isUpdatable(EmployeesList, ['admin'])} />
            <Redirect from="*" to="/users" />
          </Switch>
        </div>
      </Router>
    </div>
  );
}

function App() {
  useEffect(() => {
    document.title = 'Admin Portal';
  });

  return (
    <div className={s.App}>
      <AppBody />
    </div>
  );
}

export default App;
