import AppleIcon from '../assets/icons/apple-icon.svg';
import AndroidIcon from '../assets/icons/android-icon.svg';

let mobileDevices = [
  {
    name: 'iPhone 11 Pro',
    operatingSystem: 'IOS',
    operatingSystemVersion: '14.0.0',
    appVersion: '3.6.0',
    dateAdded: 'April 12, 2021'
  }, {
    name: 'Galaxy Tab S7+',
    operatingSystem: 'Android',
    operatingSystemVersion: '14.0.0',
    appVersion: '3.6.0',
    dateAdded: 'June 17, 2020'
  }
];

mobileDevices.map(mobileDevice => (
  {
    ...mobileDevice,
    img: mobileDevice.operatingSystem === 'IOS' ?
      AppleIcon :
      AndroidIcon
  }
));

export const getMobileDevices = () => {
  return new Promise(function(resolve) {
    setTimeout(() => {
      let data = mobileDevices;
      resolve({
        body: data,
      });
    }, 350);
  });
};

export default mobileDevices;