import React from 'react';
import infoImage from '../../assets/icons/info-icon.svg';
import s from './infoicon.module.scss';

const ArrowCompoundIcon = () => (
  <div className={s.infoIcon}>
    <img src={infoImage} alt="device" />
  </div>
);

export default ArrowCompoundIcon;