import noEmployeeImg from './noEmployees.png';
import s from './noemployeeyet.module.scss';

// TODO: update styles for this component
        // Replace image placeholder below
const NoEmployeeYet = () => (
  <div className={s.noEmployeeYet}>
    <h2>No Employee Yet..!!</h2>
    <h3>Looks like no users are in system, Once users will arrive they will reflect here</h3>
    <img src={noEmployeeImg} alt="device" />
  </div>
);

export default NoEmployeeYet;