import EditIcon from '../../../assets/icons/edit-icon.svg';
import TrashIcon from '../../../assets/icons/trash-icon.svg';
import s from './therabodydevice.module.scss';

const TherabodyDevice = ({
  deviceName = '',
  deviceType = '',
  firmware = '',
  img = '',
  onEdit = () => {},
  onDelete = () => {},
}) => (
  <div className={s.therabodyDevice}>
    <div className={s.imgContainer}>
      <img src={img} alt="device" />
    </div>
    <div className={s.body}>
      <table>
        <tbody>
          <tr>
            <td>Device Name</td>
            <td>{deviceName}</td>
          </tr>
          <tr>
            <td>Device Type</td>
            <td>{deviceType}</td>
          </tr>
          <tr>
            <td>Firmware</td>
            <td>{firmware}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className={s.footer}>
      <img src={EditIcon} alt="devices" onClick={() => onEdit(deviceName)} />
      <img src={TrashIcon} alt="devices" onClick={() => onDelete(deviceName)} />
    </div>
  </div>
);

export default TherabodyDevice;