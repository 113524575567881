import EditIcon from '../../../assets/icons/edit-icon.svg';
import TrashIcon from '../../../assets/icons/trash-icon.svg';
import s from './userslistbody.module.scss';

const getUsersBody = (
  userData = [],
  isUpdatable = false,
  goToUserDetails = () => {},
  onEdit = () => {},
  onDelete = () => {},
) => userData.map((user, i) => {
  const userRow = [
    <span className={s.clickableLink} onClick={goToUserDetails(user.id)}>
      {user.name}
    </span>,
    <span>{user.email.toLowerCase()}</span>,
    <span>{user.accountCreated}</span>,
    <span>{user.lastActive}</span>,
  ];
  if (isUpdatable) {
    userRow.push(
      <span className={s.actionItemsContainer} >
        <img src={EditIcon} alt="devices" onClick={() => onEdit(user, i)} />
        <img src={TrashIcon} alt="devices" onClick={() => onDelete(user, i)} />
      </span>
    );
  }
  return userRow;
});

export default getUsersBody;