import EditIcon from '../../../assets/icons/edit-icon.svg';
import TrashIcon from '../../../assets/icons/trash-icon.svg';
import s from './employeeslistbody.module.scss';

const getEmployeesBody = (
  employeeData = [],
  isUpdatable = false,
  onEdit = () => {},
  onDelete = () => {},
) => employeeData.map((employee, i) => {
  const employeeRow = [
    <span>{employee.name}</span>,
    <span>{employee.email.toLowerCase()}</span>,
    <span>{employee.accountCreated}</span>,
    <span>{employee.lastActive}</span>,
    <span className={s.roleSpan}>
      <div className={s[employee.role]}>{employee.role}</div>
    </span>,
  ];
  if (isUpdatable) {
    employeeRow.push(
      <span className={s.actionItemsContainer} >
        <img src={EditIcon} alt="devices" onClick={() => onEdit(employee, i)} />
        <img src={TrashIcon} alt="devices" onClick={() => onDelete(employee, i)} />
      </span>
    );
  }
  return employeeRow;
});

export default getEmployeesBody;