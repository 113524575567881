import noSearchImg from './noSearch.png';
import s from './nosearchfound.module.scss'

// TODO: update styles for this component
  // Replace image placeholder below with proper exported assets
const NoSearchFound = () => (
  <div className={s.noSearchFound}>
    <h2>No Search Found..!!</h2>
    <h3>We couldn't find what you're looking for. Please search again</h3>
    <img src={noSearchImg} alt="device" />
  </div>
);

export default NoSearchFound;