import { CircularProgress } from '@material-ui/core';
import s from './buttonprimary.module.scss';

const ButtonPrimary = ({
  content = '',
  onBtnClick = () => {},
  theme = '',
  isLoading = false,
  isDisabled = false,
}) => (
  <button
    className={`${s.buttonPrimary} ${s[theme]} ${isLoading ? s.isLoading : ''} ${isDisabled ? s.isDisabled : ''}`}
    onClick={!isDisabled ? onBtnClick : () => {}}
  >
    {content}
    <CircularProgress />
  </button>
);

export default ButtonPrimary;