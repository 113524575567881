import React from 'react';
import arrowUpImg from '../../assets/icons/arrow-drop-up-icon.svg';
import arrowDownImg from '../../assets/icons/arrow-drop-down-icon.svg';
import arrowUpFilledImg from '../../assets/icons/arrow-filled-up-icon.svg';
import arrowDownFilledImg from '../../assets/icons/arrow-filled-down-icon.svg';
import s from './arrowcompound.module.scss';

const ArrowCompoundIcon = ({
  fill = '',
}) => (
  <div className={s.arrowCompound}>
    <img src={fill === 'up' ? arrowUpFilledImg : arrowUpImg} alt="device" />
    <img src={fill === 'down' ? arrowDownFilledImg : arrowDownImg} alt="device" />
  </div>
);

export default ArrowCompoundIcon;