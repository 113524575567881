import s from './stripedtable.module.scss';

const StripedTable = ({
  headers = [],
  content = []
}) => (
  <table className={s.stripedTable}>
    <thead>
      <tr>
        {headers.map((header, h) =>
          <th key={`striped-th-${h}`}>{header}</th>
        )}
      </tr>
    </thead>
    <tbody>
      {content.map((contentRows, r) => (
        <tr key={`striped-tr-${r}`}>
          {contentRows.map((contentCell, c) => (
            <td key={`striped-td-${r}-${c}`}>{contentCell}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export default StripedTable;