import noUserImg from './noUsers.png';
import s from './nouseryet.module.scss';

// TODO: update styles for this component
        // Replace image placeholder below
const NoUserYet = () => (
  <div className={s.noUserYet}>
    <h2>No Users Yet..!!</h2>
    <h3>Looks like no users are in system, Once users will arrive they will reflect here</h3>
    <img src={noUserImg} alt="device" />
  </div>
);

export default NoUserYet;