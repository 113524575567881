import { useState } from 'react';
import { TextField, Select, MenuItem, InputLabel } from '@material-ui/core';
import s from './modaleditemployee.module.scss';

// TODO: Add validation
const ModalEditEmployee = ({
  employee = { name: '', email: '', accountCreated: '', accountUpdated: '' },
}) => {
  const [name, setName] = useState(employee.name);
  const [email, setEmail] = useState(employee.email);
  const [role, setRole] = useState(employee.role);

  const handleNameChange = (e) => {
    setName(e.target.value);
    employee.name = e.target.value;
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    employee.email = e.target.value;
  };
  const handleRoleChange = (e) => {
    employee.role = e.target.value;
    setRole(e.target.value);
  };

  return (<>
    <TextField
      required
      fullWidth
      autoFocus
      value={name}
      onChange={handleNameChange}
      label="Name"
    />
    <TextField
      required
      fullWidth
      value={email}
      onChange={handleEmailChange}
      label="Email"
    />
    <div className={s.dropdownContainer}>
      <InputLabel id="role-dropdown-label">Role</InputLabel>
      <Select
        labelId="role-dropdown-label"
        value={role}
        onChange={handleRoleChange}
        fullWidth
      >
        <MenuItem value={'Admin'}>Admin</MenuItem>
        <MenuItem value={'Viewer'}>Viewer</MenuItem>
        <MenuItem value={'Member'}>Member</MenuItem>
      </Select>
    </div>
    <TextField
      disabled
      fullWidth
      value={employee.accountCreated}
      label="Date Created"
    />
  </>);
};

export default ModalEditEmployee;