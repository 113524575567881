import React from 'react';
import ButtonPrimary from '../ButtonPrimary';
import { Dialog } from '@material-ui/core';
import s from './dialogmodal.module.scss';

// TODO: address warnings in console which only shows on development
const DialogModal = ({
  title = '',
  body = <div></div>,
  isDialogOpen = false,
  onClose = () => {},
  onSubmit = () => {},
  canSubmit = true,
  submitLabel = 'Update',
  isLoading = false,
}) => (
  <Dialog className={s.modalContainer} open={isDialogOpen} onClose={!isLoading ? onClose : () => {}}>
    <h2 className={s.modalHeader}>{title}</h2>
    {body}
    <div className={s.btnContainer}>
      <ButtonPrimary theme="noir" content="Cancel" onBtnClick={!isLoading ? onClose : () => {}} />
      <ButtonPrimary
        content={submitLabel}
        onBtnClick={!isLoading ? onSubmit : () => {}}
        isDisabled={!canSubmit}
        isLoading={isLoading}
      />
    </div>
  </Dialog>
);

export default DialogModal;