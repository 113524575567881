import ArrowCompoundIcon from '../../../components/ArrowCompoundIcon';
import InfoIcon from '../../../components/InfoIcon';
import s from './employeeslistheader.module.scss';

const getEmployeesHeader = (
  isUpdatable = false,
  ordering = {},
  setOrdering = () => {},
) => {
  const orderBy = (toOrderBy) => () => {
    if (ordering.orderBy === toOrderBy) {
      const newDirection = ordering.direction === 'up' ? 'down' : 'up';
      setOrdering({...ordering, direction: newDirection});
    } else {
      setOrdering({
        ...ordering,
        orderBy: toOrderBy,
        direction: 'up',
      });
    }
  };

  const getArrowFill = (toCheck) => {
    if (toCheck !== ordering.orderBy) return '';
    return ordering.direction;
  }

  // TODO: add hover to info icon on role header
  const employeeHeader = [
    <span className={s.clickableHeader} onClick={orderBy('name')} >
      Name <ArrowCompoundIcon fill={getArrowFill('name')} />
    </span>,
    <span className={s.clickableHeader} onClick={orderBy('email')} >
      Email <ArrowCompoundIcon fill={getArrowFill('email')} />
    </span>,
    <span className={s.clickableHeader} onClick={orderBy('accountCreated')} >
      Account Created <ArrowCompoundIcon fill={getArrowFill('accountCreated')} />
    </span>,
    <span className={s.clickableHeader} onClick={orderBy('lastActive')} >
      Last Active <ArrowCompoundIcon fill={getArrowFill('lastActive')} />
    </span>,
    <span className={s.clickableHeader} onClick={orderBy('role')} >
      Role <ArrowCompoundIcon fill={getArrowFill('role')} /><InfoIcon />
    </span>,
  ];

  if (isUpdatable) {
    employeeHeader.push(<span>Action</span>);
  }

  return employeeHeader;
};

export default getEmployeesHeader;