import ArrowCompoundIcon from '../../../components/ArrowCompoundIcon';
import s from './userslistheader.module.scss';

const getUsersHeader = (
  isUpdatable = false,
  ordering = {},
  setOrdering = () => {},
) => {
  const orderBy = (toOrderBy) => () => {
    if (ordering.orderBy === toOrderBy) {
      const newDirection = ordering.direction === 'up' ? 'down' : 'up';
      setOrdering({...ordering, direction: newDirection});
    } else {
      setOrdering({
        ...ordering,
        orderBy: toOrderBy,
        direction: 'up',
      });
    }
  };

  const getArrowFill = (toCheck) => {
    if (toCheck !== ordering.orderBy) return '';
    return ordering.direction;
  };

  const userHeader = [
    <span className={s.clickableHeader} onClick={orderBy('name')} >
      Name <ArrowCompoundIcon fill={getArrowFill('name')} />
    </span>,
    <span className={s.clickableHeader} onClick={orderBy('email')} >
      Email <ArrowCompoundIcon fill={getArrowFill('email')} />
    </span>,
    <span className={s.clickableHeader} onClick={orderBy('accountCreated')} >
      Account Created <ArrowCompoundIcon fill={getArrowFill('accountCreated')} />
    </span>,
    <span className={s.clickableHeader} onClick={orderBy('lastActive')} >
      Last Active <ArrowCompoundIcon fill={getArrowFill('lastActive')} />
    </span>,
  ];
  if (isUpdatable) {
    userHeader.push(<span>Action</span>);
  }
  return userHeader;
};

export default getUsersHeader;