import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Local Components
import NoUserYet from './NoUserYet';
import getUsersHeader from './UsersListHeader';
import getUsersBody from './UsersListBody';
import ModalEditUser from './ModalEditUser';
import ModalDeleteUser from './ModalDeleteUser';
// Global Components
import PageHeader from '../../components/PageHeader';
import SearchInput from '../../components/SearchInput';
import NumberDropdown from '../../components/NumberDropdown';
import StripedTable from '../../components/StripedTable';
import PageSelector from '../../components/PageSelector';
import LoadingIcon from '../../components/LoadingIcon';
import NoSearchFound from '../../components/NoSearchFound';
import DialogModal from '../../components/DialogModal';
import Notification from '../../components/Notification';
// Utils
import { getUsers, updateUser, deleteUser } from '../../utils/mock-user-data';
// Styles
import s from './userslist.module.scss';

const UsersList = ({
  isUpdatable = false, // tracks if current logged in user can make updates
}) => {
  // TODO: set some of these values as search params or localstorage
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userCount, setUserCount] = useState(0);
  const [searchVal, setSearchVal] = useState('');
  const [ordering, setOrdering] = useState({
    orderBy: 'accountCreated',
    direction: 'down',
  });
  const [notification, setNotification] = useState({
    show: false,
    onClose: () => setNotification({...notification, show: false})
  });
  const [modal, setModal] = useState({
    isDialogOpen: false,
    onClose: () => setModal({...modal, isDialogOpen: false}),
  });
  const [modalSubmitEnabled, enableModalSubmit] = useState(true);
  const [modalLoading, toggleModalLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const orderDirection = ordering.direction === 'up' ? 'asc' : 'desc';
    // TODO: replace mock api call
    return getUsers(rowsPerPage, currentPage, searchVal, ordering.orderBy, orderDirection)
      .then((response) => {
        setUsers(response.body);
        setUserCount(response.total)
        setLoading(false);
      });
  }, [rowsPerPage, currentPage, searchVal, ordering]);

  const showNotification = (message = '') => {
    setNotification({...notification, show: true, message});
  };

  let history = useHistory();
  const goToUserDetails = (userId) => () => {
    history.push(`/users/${userId}`);
  };

  const handleSearchUpdate = (val) => {
    setSearchVal(val);
    setCurrentPage(1);
  };

  const handleRowsPerPageUpdate = (num) => {
    setRowsPerPage(num);
    setCurrentPage(1);
  };

  const handleUserEdit = (user, index) => {
    const modalData = {...user};
    enableModalSubmit(true);
    toggleModalLoading(false);
    setModal({
      ...modal,
      isDialogOpen: true,
      title: 'Edit User Detail',
      body: <ModalEditUser user={modalData}/>,
      onSubmit: () => {
        toggleModalLoading(true);
        // TODO: replace mock api call to update user
        updateUser(user.id, user)
        .then(() => {
          const updatedUserList = [...users];
          updatedUserList[index] = modalData;
          setUsers(updatedUserList);
          showNotification(`A user ${user.name}'s details updated successfully.`);
          modal.onClose();
        });
      },
    });
  };

  const handleUserDelete = (user, index) => {
    const modalData = {...user};
    enableModalSubmit(false);
    toggleModalLoading(false);
    setModal({
      ...modal,
      isDialogOpen: true,
      title: 'Delete User?',
      body: <ModalDeleteUser user={modalData} enableSubmit={() => enableModalSubmit(true)} />,
      submitLabel: 'Delete',
      onSubmit: () => {
        toggleModalLoading(true);
        // TODO: replace mock api call to delete user
        deleteUser(user.id)
        .then(() => {
          const updatedUserList = [...users];
          updatedUserList.splice(index, 1);
          setUsers(updatedUserList);
          showNotification(`A user ${user.name}'s is successfully deleted.`);
          modal.onClose();
        });
      },
    });
  };

  let maxPages = userCount / rowsPerPage;
  maxPages = maxPages > 0 ? maxPages : 1;

  const hasUserRows = !!users.length;
  const hasSearchInput = !!searchVal;
  return (
    <div className={s.usersListPage}>
      <div className={s.usersListHeader}>
        <div className={s.headerTopSection}>
          <PageHeader header={'User List'} subheader={`User Count: ${userCount}`} />
        </div>
        <div className={s.headerBottomSection}>
          <SearchInput placeholder={'Search users by name'} onUpdate={handleSearchUpdate} />
          <NumberDropdown val={rowsPerPage} options={[5,10,15,20]} onUpdate={handleRowsPerPageUpdate} />
        </div>
      </div>
      <div className={s.usersListBody}>
        {isLoading && <LoadingIcon theme={'big-center'} />}
        {!isLoading && hasUserRows &&
          <StripedTable
            headers={getUsersHeader(isUpdatable, ordering, setOrdering)}
            content={getUsersBody(users, isUpdatable, goToUserDetails, handleUserEdit, handleUserDelete)}
          />
        }
        {!isLoading && !hasUserRows && !hasSearchInput && <NoUserYet />}
        {!isLoading && !hasUserRows && hasSearchInput && <NoSearchFound />}
      </div>
      {!isLoading && hasUserRows &&
        <div className={s.usersListFooter}>
          <PageSelector page={currentPage} maxPages={maxPages} onPageUpdate={setCurrentPage} />
        </div>
      }
      <Notification {...notification} />
      <DialogModal {...modal} canSubmit={modalSubmitEnabled} isLoading={modalLoading} />
    </div>
  );
};

export default UsersList;