import { MenuItem, Select } from '@material-ui/core';
import s from './numberdropdown.module.scss';

const PageDropdown = ({
  val = 1,
  onUpdate = () => {},
  options = [1,2,3,4,5,6,7,8,9,10],
  label = 'Rows per page',
}) => (
  <div className={s.pageDropdownContainer}>
    <span>{label}</span>
    <Select value={val} onChange={e => onUpdate(e.target.value)} >
      {options.map((num) =>
        <MenuItem value={num} key={`select-pg-${num}`}>
          {num}
        </MenuItem>)}
    </Select>
  </div>
);

export default PageDropdown;