import ButtonPrimary from '../../../components/ButtonPrimary';
import DoubleCheckIcon from '../../../components/DoubleCheckIcon';
import s from './userinfo.module.scss';

const UserDetail = ({
  header = '',
  body = '',
  color = '',
  withCheck = false,
}) => (
  <div className={s.userDetail}>
    <span className={s.userDetailHeader}>
      {header}
    </span>
    <span className={`${s.userDetailBody} ${color ? s[color] : ''}`}>
      {withCheck && <DoubleCheckIcon />}
      {body}
    </span>
  </div>
);

const UserInfo = ({
  emailStatus = '',
  accountStatus = '',
  name = '',
  email = '',
  entitlement = '',
  accountCreated = '',
  accountUpdated = '',
  onVerifyEmail = () => {},
  isLoading = false,
}) => (
  <div className={s.userInfo}>
    <UserDetail
      header="Email Status"
      color="blue"
      withCheck={emailStatus !== 'pending'}
      body={emailStatus !== 'pending' ?
        emailStatus :
        <ButtonPrimary
          content="Verify Email"
          onBtnClick={onVerifyEmail}
          isLoading={isLoading}
        />
      }
    />
    <UserDetail
      header="Account Status"
      body={accountStatus}
      withCheck
      color="green"
    />
    <UserDetail header="Name" body={name}/>
    <UserDetail header="Date Created" body={accountCreated}/>
    <UserDetail header="Email" body={email}/>
    <UserDetail header="Date Updated" body={accountUpdated}/>
    <UserDetail header="Entitlement" body={entitlement} withCheck/>
  </div>
);

export default UserInfo;