import { TextField } from '@material-ui/core';

const ModalDeleteTBDevice = ({
  tbDevice = {},
  enableSubmit = () => {},
}) => {
  const checkNameMatch = (e = { target: { value: '' } }) => {
    const deviceName = tbDevice.deviceName || '';
    const inputVal = (e.target || {}).value || '';
    if (deviceName.toLowerCase() === inputVal.toLowerCase()) {
      enableSubmit();
    }
  };

  return (<>
    <p>Are you sure you want to delete {tbDevice.deviceName} device? Because
once you delete this, then it will be remove forever.</p>
    <p>To confirm please enter the device name below</p>
    <TextField
      required
      fullWidth
      autoFocus
      label="Enter the Name of device"
      onChange={checkNameMatch}
    />
  </>);
};

export default ModalDeleteTBDevice;