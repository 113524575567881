import { useEffect, useState } from 'react';
// Local Components
import NoEmployeeYet from './NoEmployeeYet';
import ModalNewEmployee from './ModalNewEmployee';
import ModalDeleteEmployee from './ModalDeleteEmployee';
import ModalEditEmployee from './ModalEditEmployee';
import getEmployeesHeader from './EmployeesListHeader';
import getEmployeesBody from './EmployeesListBody';
// Global Components
import DialogModal from '../../components/DialogModal';
import PageHeader from '../../components/PageHeader';
import ButtonPrimary from '../../components/ButtonPrimary';
import SearchInput from '../../components/SearchInput';
import NumberDropdown from '../../components/NumberDropdown';
import StripedTable from '../../components/StripedTable';
import PageSelector from '../../components/PageSelector';
import LoadingIcon from '../../components/LoadingIcon';
import NoSearchFound from '../../components/NoSearchFound';
import Notification from '../../components/Notification';
// Utils
import { getEmployees, createEmployee, updateEmployee, deleteEmployee } from '../../utils/mock-employee-data';
// Styles
import s from './employeeslist.module.scss';

const EmployeesList = ({
  isUpdatable = false, // tracks if current logged in user can make updates
}) => {
  // TODO: set some of these values as search params or localstorage
  const [employees, setEmployees] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [searchVal, setSearchVal] = useState('');
  const [ordering, setOrdering] = useState({
    orderBy: 'name',
    direction: 'up',
  });
  const [notification, setNotification] = useState({
    show: false,
    onClose: () => setNotification({...notification, show: false})
  });
  const [modal, setModal] = useState({
    isDialogOpen: false,
    onClose: () => setModal({...modal, isDialogOpen: false}),
  });
  const [modalSubmitEnabled, enableModalSubmit] = useState(true);
  const [modalLoading, toggleModalLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const orderDirection = ordering.direction === 'up' ? 'asc' : 'desc';
    // TODO: Replace mock api call
    getEmployees(rowsPerPage, currentPage, searchVal, ordering.orderBy, orderDirection)
      .then((response) => {
        setEmployees(response.body);
        setEmployeeCount(response.total)
        setLoading(false);
      });
  }, [rowsPerPage, currentPage, searchVal, ordering]);

  const handleSearchUpdate = (val) => {
    setSearchVal(val);
    setCurrentPage(1);
  };

  const handleRowsPerPageUpdate = (num) => {
    setRowsPerPage(num);
    setCurrentPage(1);
  };

  const showNotification = (message = '') => {
    setNotification({...notification, show: true, message});
  };

  const handleNewEmployee = () => {
    const modalData = {};
    enableModalSubmit(false);
    toggleModalLoading(false);
    setModal({
      ...modal,
      isDialogOpen: true,
      title: 'Add New Employee',
      body: <ModalNewEmployee employee={modalData} onValidate={enableModalSubmit} />,
      submitLabel: 'Send Invitation',
      onSubmit: () => {
        toggleModalLoading(true);
        // TODO: replace mock api call to update user
        createEmployee(modalData)
        .then(() => {
          showNotification(`Invitation Sent`);
          modal.onClose();
        });
      },
    });
  };

  const handleEmployeeEdit = (employee, index) => {
    const modalData = {...employee};
    enableModalSubmit(true);
    toggleModalLoading(false);
    setModal({
      ...modal,
      isDialogOpen: true,
      title: 'Edit Emloyee Details',
      body: <ModalEditEmployee employee={modalData} onValidate={enableModalSubmit} />,
      submitLabel: 'Send Invitation',
      onSubmit: () => {
        toggleModalLoading(true);
        // TODO: replace mock api call to update user
        updateEmployee(employee.id, modalData)
        .then(() => {
          const updatedEmployeesList = [...employees];
          updatedEmployeesList[index] = modalData;
          setEmployees(updatedEmployeesList);
          showNotification(`An employee ${employee.name}'s details updated successfully.`);
          modal.onClose();
        });
      },
    });
  };

  const handleEmployeeDelete = (employee, index) => {
    const modalData = {...employee};
    enableModalSubmit(false);
    toggleModalLoading(false);
    setModal({
      ...modal,
      isDialogOpen: true,
      title: 'Delete Employee?',
      body: <ModalDeleteEmployee employee={modalData} enableSubmit={() => enableModalSubmit(true)} />,
      submitLabel: 'Send Invitation',
      onSubmit: () => {
        toggleModalLoading(true);
        // TODO: replace mock api call to update user
        deleteEmployee(employee.id)
        .then(() => {
          const updatedEmployeesList = [...employees];
          updatedEmployeesList.splice(index, 1);
          setEmployees(updatedEmployeesList);
          showNotification(`An employee ${employee.name}'s is successfully deleted.`);
          modal.onClose();
        });
      },
    });
  };

  let maxPages = employeeCount / rowsPerPage;
  maxPages = maxPages > 0 ? maxPages : 1;

  const hasEmployeeRows = !!employees.length;
  const hasSearchInput = !!searchVal;
  return (
    <div className={s.employeesListPage}>
      <div className={s.employeesListHeader}>
        <div className={s.headerTopSection}>
          <PageHeader header={'Employee List'} subheader={`Employee Count: ${employeeCount}`} />
          {isUpdatable &&
            <div className={s.buttonContainer}>
              <ButtonPrimary content={'Add New Employee'} onBtnClick={handleNewEmployee} />
            </div>
          }
        </div>
        <div className={s.headerBottomSection}>
          <SearchInput placeholder={'Search employees by name, email'} onUpdate={handleSearchUpdate} />
          <NumberDropdown val={rowsPerPage} options={[5,10,15,20]} onUpdate={handleRowsPerPageUpdate} />
        </div>
      </div>
      <div className={s.employeesListBody}>
        {isLoading && <LoadingIcon theme={'big-center'} />}
        {!isLoading && hasEmployeeRows &&
          <StripedTable
            headers={getEmployeesHeader(isUpdatable, ordering, setOrdering)}
            content={getEmployeesBody(employees, isUpdatable, handleEmployeeEdit, handleEmployeeDelete)}
          />
        }
        {!isLoading && !hasEmployeeRows && !hasSearchInput &&<NoEmployeeYet />}
        {!isLoading && !hasEmployeeRows && hasSearchInput && <NoSearchFound />}
      </div>
      {!isLoading && hasEmployeeRows &&
        <div className={s.employeesListFooter}>
          <PageSelector page={currentPage} maxPages={maxPages} onUpdate={setCurrentPage} />
        </div>
      }
      <Notification {...notification} />
      <DialogModal {...modal} canSubmit={modalSubmitEnabled} isLoading={modalLoading} />
    </div>
  );
};

export default EmployeesList;