const employees = [
  {
    name: 'Lina Greathouse',
    email: 'Lina.Greathouse@gmail.com',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Shalonda Rinke',
    email: 'Shalonda.Rinke@gmail.com',
    role: 'viewer',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Belva Facemire',
    email: 'Belva.Facemire@gmail.com',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Pricilla Conatser',
    email: 'Pricilla.Conatser@gmail.com',
    role: 'member',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Jama Gartland',
    email: 'Jama.Gartland@gmail.com',
    role: 'member',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Michaela Sabbagh',
    email: 'Michaela.Sabbagh@gmail.com',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Carlotta Mujica',
    email: 'Carlotta.Mujica@gmail.com',
    role: 'viewer',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Remedios Kowalsky',
    email: 'Remedios.Kowalsky@gmail.com',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Evan Feltz',
    email: 'Evan.Feltz@gmail.com',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Sharmaine Albarran',
    email: 'Sharmaine.Albarran@gmail.com',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Margene Luman',
    email: 'Margene.Luman@gmail.com',
    role: 'viewer',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Cythia Pearse',
    email: 'Cythia.Pearse@gmail.com',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Gena Stanhope',
    email: 'Gena.Stanhope@gmail.com',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Skye Khan',
    email: 'Skye.Khan@gmail.com',
    role: 'member',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Tera Betty',
    email: 'Tera.Betty@gmail.com',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Nona Hankerson',
    email: 'Nona.Hankerson@gmail.com',
    role: 'member',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Charlyn Montandon',
    email: 'Charlyn.Montandon@gmail.com',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Anthony Hunsucker',
    email: 'Anthony.Hunsucker@gmail.com',
    role: 'viewer',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Todd Bruns',
    email: 'Todd.Bruns@gmail.com',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Freddy Throneberry',
    email: 'Freddy.Throneberry@gmail.com',
    role: 'member',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Alexander Allan',
    email: 'alexander.allan@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Austin MacLeod',
    email: 'austin.macleod@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Frank Parr',
    email: 'frank.parr@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Sam Mackenzie',
    email: 'sam.mackenzie@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Luke Metcalfe',
    email: 'luke.metcalfe@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Brandon Rampling',
    email: 'brandon.rampling@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Leah Paterson',
    email: 'leah.paterson@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Una Sanderson',
    email: 'una.sanderson@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Chloe McLean',
    email: 'chloe.mclean@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Anna Lee',
    email: 'anna.lee@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Stewart Gray',
    email: 'stewart.gray@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Joanne Mills',
    email: 'joanne.mills@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Felicity Hardacre',
    email: 'felicity.hardacre@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Wanda MacLeod',
    email: 'wanda.macleod@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Benjamin Hemmings',
    email: 'benjamin.hemmings@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Alison Baker',
    email: 'alison.baker@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Robert Mackay',
    email: 'robert.mackay@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Stewart Welch',
    email: 'stewart.welch@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Blake Dowd',
    email: 'blake.dowd@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Rebecca Ince',
    email: 'rebecca.ince@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Deirdre Knox',
    email: 'deirdre.knox@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Jasmine Ross',
    email: 'jasmine.ross@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Joseph Kerr',
    email: 'joseph.kerr@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Cameron Cameron',
    email: 'cameron.cameron@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Anthony Underwood',
    email: 'anthony.underwood@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Simon Peake',
    email: 'simon.peake@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Benjamin Mackay',
    email: 'benjamin.mackay@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Faith Manning',
    email: 'faith.manning@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Vanessa Vance',
    email: 'vanessa.vance@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  }, {
    name: 'Vanessa Gibson',
    email: 'vanessa.gibson@gmail',
    role: 'admin',
    accountCreated: 'Nov 4, 2020',
    lastActive: '10:15 AM, Yesterday',
  },
];

export const getEmployees = (
  rowsPerPage = 5,
  currentPage = 1,
  filter = '',
  sortBy = 'name',
  sortDirection = 'asc',
) => {
  return new Promise(function(resolve) {
    setTimeout(() => {
      let data = employees;
      if (filter) {
        data = data
          .filter(e =>
            `${e.name}|${e.email}`.toLowerCase()
              .includes(filter.toLowerCase())
          );
      }
      if (sortBy) {
        data = data
          .sort((employeeA, employeeB) => {
            if (employeeA[sortBy].toLowerCase() < employeeB[sortBy].toLowerCase()) {
              return sortDirection === 'asc' ? -1 : 1
            }
            if (employeeA[sortBy].toLowerCase() > employeeB[sortBy].toLowerCase()) {
              return sortDirection === 'asc' ? 1 : -1
            }
            return 0
          });
      }
      resolve({
        body: data
          .slice(
            (currentPage - 1) * rowsPerPage,
            currentPage * rowsPerPage,
          ),
        total: data.length,
      });
    }, 500);
  });
};

export const createEmployee = (
  employee = {},
) => {
  return new Promise(function(resolve) {
    setTimeout(() => {
      resolve({status: 'success'});
    }, 500);
  });
};

export const updateEmployee = (
  id = 0,
  employee = {},
) => {
  return new Promise(function(resolve) {
    setTimeout(() => {
      resolve({status: 'success'});
    }, 500);
  });
};

export const deleteEmployee = (
  id = 0,
) => {
  return new Promise(function(resolve) {
    setTimeout(() => {
      resolve({status: 'success'});
    }, 500);
  });
};

export default employees;