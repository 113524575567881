import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const Notification = ({
  onClose = () => {},
  show = false,
  type = 'success',
  message = '',
}) => (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    open={show}
    autoHideDuration={3000}
    onClose={onClose}
  >
    <Alert onClose={onClose} severity={type}>{message}</Alert>
  </Snackbar>
);

export default Notification;