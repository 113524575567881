import { ArrowBack } from '@material-ui/icons';
import s from './pageheader.module.scss';

const PageHeader = ({
  header = '',
  subheader = '',
  backButton = false,
  onBack = () => {},
}) => (
  <div className={s.titleContainer}>
    <h2>
      {backButton && <ArrowBack onClick={onBack} />}
      {header}
    </h2>
    {subheader && <p>{subheader}</p>}
  </div>
);

export default PageHeader;