import AndroidIcon from '../../../assets/icons/android-icon.svg';
import AppleIcon from '../../../assets/icons/apple-icon.svg';
import s from './mobiledevice.module.scss';

const MobileDevice = ({
  name = '',
  operatingSystem = 'IOS',
  operatingSystemVersion = '',
  appVersion = '',
  dateAdded = '',
}) => (
  <div className={s.mobileDevice}>
    <div className={s.header}>
      <div className={s.mobileLogo}>
        <img src={operatingSystem === 'IOS' ? AppleIcon : AndroidIcon} alt="device" />
      </div>
      <div className={s.titleContainer}>
        <span>{operatingSystemVersion}</span>
        <h4>{name}</h4>
      </div>
    </div>
    <div className={s.body}>
      <table>
        <tbody>
          <tr>
            <td>Operating System</td>
            <td>{operatingSystem}</td>
          </tr>
          <tr>
            <td>Operating System Version</td>
            <td>{operatingSystemVersion}</td>
          </tr>
          <tr>
            <td>App Version</td>
            <td>{appVersion}</td>
          </tr>
          <tr>
            <td>Date Added</td>
            <td>{dateAdded}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default MobileDevice;