import therabodyEmptyImg from '../../../assets/therabody-empty-list.png';
import s from './therabodyempty.module.scss';

// TODO: The styling for this component needs some work
const TherabodyEmpty = () => (
  <div className={s.therabodyEmptyDeviceList}>
    <img src={therabodyEmptyImg} alt="device" />
    <div className={s.emptyTextContainer}>
      <h4>No Device Found..!!</h4>
      <p>We are unable to fetch the devices of user.</p>
      <p>Please check with the user.</p>
    </div>
  </div>
);

export default TherabodyEmpty;