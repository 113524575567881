import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// Local Components
import MobileDevice from './MobileDevice'
import TherabodyDevice from './TherabodyDevice';
import TherabodyEmpty from './TherabodyEmpty';
import UserInfo from './UserInfo';
import ModalEditTBDevice from './ModalEditTBDevice';
import ModalDeleteTBDevice from './ModalDeleteTBDevice';
import ModalEditUser from '../UsersList/ModalEditUser'
import ModalDeleteUser from '../UsersList/ModalDeleteUser'
// Global Components
import PageHeader from '../../components/PageHeader';
import LoadingIcon from '../../components/LoadingIcon';
import ButtonPrimary from '../../components/ButtonPrimary';
import Notification from '../../components/Notification';
import DialogModal from '../../components/DialogModal';
// Utils
import { getUser, verifyEmail, deleteUser, updateUser } from '../../utils/mock-user-data';
import { getTherabodyDevices, deleteDevice, updateDevice } from '../../utils/mock-therabody-devices';
import { getMobileDevices } from '../../utils/mock-mobile-devices';
// Styles
import s from './userdetail.module.scss';

const UserDetail = ({
  isUpdatable = false, // tracks if current logged in user can make updates
}) => {
  const [user, setUser] = useState({});
  const [mobileDevices, setMobileDevices] = useState([]);
  const [therabodyDevices, setTherabodyDevices] = useState([]);
  const [showAllTBDevices, setShowAllTBDevices] = useState(false);
  const [isLoading, setLoading] = useState({});
  const [notification, setNotification] = useState({
    show: false,
    onClose: () => setNotification({...notification, show: false})
  });
  const [modal, setModal] = useState({
    isDialogOpen: false,
    onClose: () => setModal({...modal, isDialogOpen: false}),
  });
  const [modalSubmitEnabled, enableModalSubmit] = useState(true);
  const [modalLoading, toggleModalLoading] = useState(false);

  let history = useHistory();
  let { id:userId } = useParams();

  useEffect(() => {
    const loadingState = {
      userInfo: true,
      mobileDevices: true,
      therabodyDevices: true,
    };
    setLoading(loadingState);
    // TODO: Replace mock api calls
    getUser(userId)
      .then((response) => {
        setUser(response.body);
        loadingState.userInfo = false;
        setLoading({...loadingState});
      });
    getMobileDevices(userId)
      .then((response) => {
        setMobileDevices(response.body);
        loadingState.mobileDevices = false;
        setLoading({...loadingState});
      });
    getTherabodyDevices(userId)
      .then((response) => {
        setTherabodyDevices(response.body);
        loadingState.therabodyDevices = false;
        setLoading({...loadingState});
      });
  }, [userId]);

  const showNotification = (message = '') => {
    setNotification({...notification, show: true, message});
  };

  const handleVerifyEmail = () => {
    setLoading({...isLoading, verifyEmail: true});
    verifyEmail()
      .then(() => {
        setLoading({...isLoading, verifyEmail: false});
        showNotification(`${user.name} has verified their email.`);
        setUser({...user, emailStatus: 'verified'})
      });
  };

  const handleTBDeviceEdit = (tbDevice, index) => () => {
    const modalData = {...tbDevice};
    enableModalSubmit(true);
    toggleModalLoading(false);
    setModal({
      ...modal,
      isDialogOpen: true,
      title: 'Edit Device Name',
      body: <ModalEditTBDevice tbDevice={modalData}/>,
      onSubmit: () => {
        toggleModalLoading(true);
        // TODO: add api call to update device
        updateDevice(tbDevice.deviceName, modalData)
        .then(() => {
          const updatedDeviceList = [...therabodyDevices];
          updatedDeviceList[index] = modalData;
          setTherabodyDevices(updatedDeviceList);
          showNotification(`A device ${tbDevice.deviceName}'s details updated successfully.`);
          modal.onClose();
        })
      },
    });
  };

  const handleTBDeviceDelete = (tbDevice, index) => () => {
    const modalData = {...tbDevice};
    enableModalSubmit(false);
    toggleModalLoading(false);
    setModal({
      ...modal,
      isDialogOpen: true,
      title: 'Delete Device?',
      body: <ModalDeleteTBDevice tbDevice={modalData} enableSubmit={() => enableModalSubmit(true)} />,
      submitLabel: 'Delete',
      onSubmit: () => {
        toggleModalLoading(true);
        // TODO: add api call to delete device
        deleteDevice(tbDevice.deviceName)
        .then(() => {
          const updatedDeviceList = [...therabodyDevices];
          updatedDeviceList.splice(index, 1);
          setTherabodyDevices(updatedDeviceList);
          showNotification(`A device ${tbDevice.deviceName} is successfully deleted.`);
          modal.onClose();
        });
      },
    });
  };

  const handleUserEdit = () => {
    const modalData = {...user};
    enableModalSubmit(true);
    toggleModalLoading(false);
    setModal({
      ...modal,
      isDialogOpen: true,
      title: 'Edit User Detail',
      body: <ModalEditUser user={modalData}/>,
      onSubmit: () => {
        toggleModalLoading(true);
        // TODO: replace mock api call to update user
        updateUser(user.id, user)
        .then(() => {
          setUser(modalData);
          showNotification(`A user ${user.name}'s is successfully deleted.`);
          modal.onClose();
        });
      },
    });
  };

  const handleUserDelete = () => {
    const modalData = {...user};
    enableModalSubmit(false);
    toggleModalLoading(false);
    setModal({
      ...modal,
      isDialogOpen: true,
      title: 'Delete User?',
      body: <ModalDeleteUser user={modalData} enableSubmit={() => enableModalSubmit(true)} />,
      submitLabel: 'Delete',
      onSubmit: () => {
        toggleModalLoading(true);
        // TODO: replace mock api call to delete user
        deleteUser(user.id)
        .then(() => {
          modal.onClose();
          history.replace(`/users`);
          // TODO: fix issue regarding the following notification not showing after redirection
          showNotification(`A user ${user.name}'s details updated successfully.`);
        });
      },
    });
  };

  return (
    <div className={s.userDetailPage}>
      <PageHeader header={'User Details'} backButton={true} onBack={() => history.push(`/users`)} />
      <div className={s.userDetailBody}>
        <div className={s.informationSection}>
          <h3>General Information</h3>
          {isLoading.userInfo && <LoadingIcon />}
          {!isLoading.userInfo &&
            <UserInfo {...user} onVerifyEmail={handleVerifyEmail} isLoading={isLoading.verifyEmail} />
          }
          {!isLoading.userInfo && isUpdatable &&
            <div className={s.buttonsContainer}>
              <ButtonPrimary theme="dark" content="Edit" onBtnClick={handleUserEdit} />
              <ButtonPrimary theme="noir" content="Change Password" />
              <ButtonPrimary theme="danger" content="Delete User" onBtnClick={handleUserDelete} />
            </div>
          }
          <h3 className={s.mobileHeader}>Device Information</h3>
          {isLoading.mobileDevices && <LoadingIcon />}
          <div className={s.mobileList}>
            {!isLoading.mobileDevices &&
              mobileDevices.map((mDevice, k) => <MobileDevice key={k} {...mDevice} />)
            }
          </div>
        </div>
        <div className={s.deviceListContainer}>
          <h3>Therabody Device List ({therabodyDevices.length})</h3>
          {isLoading.therabodyDevices && <LoadingIcon />}
          <div className={s.deviceList}>
            {!isLoading.therabodyDevices &&
              therabodyDevices
                .slice(0, showAllTBDevices ? therabodyDevices.length : 3)
                .map((tbDevice, k) => (
                  <TherabodyDevice
                    {...tbDevice}
                    key={k}
                    onEdit={handleTBDeviceEdit(tbDevice, k)}
                    onDelete={handleTBDeviceDelete(tbDevice, k)}
                  />
                ))
            }
            {!isLoading.therabodyDevices && !therabodyDevices.length && <TherabodyEmpty />}
            {!isLoading.therabodyDevices && !showAllTBDevices && !!therabodyDevices.length &&
              <ButtonPrimary theme="dark" content="See All" onBtnClick={() => setShowAllTBDevices(true)} />
            }
          </div>
        </div>
      </div>
      <Notification {...notification} />
      <DialogModal {...modal} canSubmit={modalSubmitEnabled} isLoading={modalLoading} />
    </div>
  );
};

export default UserDetail;