import { Pagination } from '@material-ui/lab';
import s from './pageselector.module.scss';

const PageSelector = ({
  page = 1,
  onPageUpdate = () => {},
  maxPages = 1,
}) => {
  const handlePageChange = (_, val) => {
    onPageUpdate(val);
  };

  return (
    <div className={s.pageSelector}>
      <Pagination
        count={maxPages}
        page={page}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default PageSelector;