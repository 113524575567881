import { logoutUser } from '../../utils/mock-auth';
import s from './unauthorized.module.scss';

// TODO: Replace current placeholder content for unauthorized page
const Unauthorized = () => {
  const handleLogout = () => {
    logoutUser();
    window.location.reload();
  };

  return (
    <div className={s.unauthorizedPage}>
      <h1>Forbidden . . . . how'd you get here!??</h1>
      <button className={s.logoutButton} onClick={handleLogout}>
        Logout
      </button>
    </div>
  )
};

export default Unauthorized;