import React from 'react';
import loadingGif from '../../assets/icons/loader-icon.gif';
import s from './loadingicon.module.scss';

const LoadingIcon = ({
  theme = 'default',
}) => (
  <div className={`${s.loadingIcon} ${s[theme]}`}>
    <img src={loadingGif} alt="device" />
  </div>
);

export default LoadingIcon;