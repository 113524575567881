import theragunElite from '../assets/theragun-devices/elite.png';
import theragunMini from '../assets/theragun-devices/mini.png';
import theragunPrime from '../assets/theragun-devices/prime.png';
import theragunPro from '../assets/theragun-devices/pro.png';
import waveDuo from '../assets/theragun-devices/waveDuo.png';
import waveRoller from '../assets/theragun-devices/waveRoller.png';
import waveSolo from '../assets/theragun-devices/waveSolo.png';
import powerDotUno from '../assets/theragun-devices/powerDotUno.png';
import powerDotDuo from '../assets/theragun-devices/powerDotDuo.png';
import recoveryAir from '../assets/theragun-devices/recoveryAir.png';
import recoveryAirPro from '../assets/theragun-devices/recoveryAirPro.png';

const therabodyDevices = [
  {
    deviceName: 'Device 1',
    deviceType: 'Power Dot Uno',
    firmware: '1.00 - 2.1.3',
    img: powerDotUno,
  }, {
    deviceName: 'Device 2',
    deviceType: 'Power Dot Duo',
    firmware: '1.00 - 2.1.3',
    img: powerDotDuo,
  }, {
    deviceName: 'Device 3',
    deviceType: 'Recovery Air',
    firmware: '1.00 - 2.1.3',
    img: recoveryAir,
  }, {
    deviceName: 'Device 4',
    deviceType: 'Recovery Air Pro',
    firmware: '1.00 - 2.1.3',
    img: recoveryAirPro,
  }, {
    deviceName: 'Device 5',
    deviceType: 'Theragun Elite',
    firmware: '1.00 - 2.1.3',
    img: theragunElite,
  }, {
    deviceName: 'Device 6',
    deviceType: 'Theragun Mini',
    firmware: '1.00 - 2.1.3',
    img: theragunMini,
  }, {
    deviceName: 'Device 7',
    deviceType: 'Theragun Prime',
    firmware: '1.00 - 2.1.3',
    img: theragunPrime,
  }, {
    deviceName: 'Device 8',
    deviceType: 'Theragun Pro',
    firmware: '1.00 - 2.1.3',
    img: theragunPro,
  }, {
    deviceName: 'Device 9',
    deviceType: 'Wave Roller',
    firmware: '1.00 - 2.1.3',
    img: waveRoller,
  }, {
    deviceName: 'Device 10',
    deviceType: 'Wave Solo',
    firmware: '1.00 - 2.1.3',
    img: waveSolo,
  }, {
    deviceName: 'Device 10',
    deviceType: 'Wave Duo',
    firmware: '1.00 - 2.1.3',
    img: waveDuo
  },
];

export const getTherabodyDevices = (userId = 0) => {
  return new Promise(function(resolve) {
    setTimeout(() => {
      let data = therabodyDevices;
      resolve({
        // Mock empty device list every 3rd user
        body: ((userId + 1) % 3) === 0 ? [] : data,
      });
    }, 200);
  });
};

export const updateDevice = (
  oldName = '',
  device,
) => {
  return new Promise(function(resolve) {
    setTimeout(() => {
      resolve({status: 'success'});
    }, 500);
  });
};

export const deleteDevice = (
  id = 0,
) => {
  return new Promise(function(resolve) {
    setTimeout(() => {
      resolve({status: 'success'});
    }, 500);
  });
};

export default therabodyDevices;