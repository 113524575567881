import { useState } from 'react';
import { TextField, Select, MenuItem, InputLabel } from '@material-ui/core';
import CheckIcon from '../../../components/CheckIcon';
import s from './modalnewemployee.module.scss';

const PERMISSIONS = {
  'Admin': ['Edit, Delete users', 'Search Users', 'Crate, Edit, Delete Employees', 'Search Employees'],
  'Member': ['Edit, Delete users', 'Search Users', 'View Employees', 'Search Employees'],
  'Viewer': ['View users', 'Search Users', 'View Employees', 'Search Employees'],
};

// TODO: This modal needs some functional & styling work
  // error validation / styling on form
const ModalNewEmployee = ({
  employee = {
    role: 'Viewer',
  },
  onValidate = () => {},
}) => {
  const [role, setRole] = useState(employee.role || 'Viewer');
  const [name, setName] = useState(employee.name);
  const [email, setEmail] = useState(employee.email);

  const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const validate = () => {
    if (name && email && isValidEmail(email) && role) {
      onValidate(true);
    } else {
      onValidate(false);
    }
  };

  const handleRoleChange = (e) => {
    employee.role = e.target.value;
    setRole(e.target.value);
    validate();
  };
  const handleNameChange = (e) => {
    employee.name = e.target.value;
    setName(e.target.value);
    validate();
  };
  const handleEmailChange = (e) => {
    employee.email = e.target.value;
    setEmail(e.target.value);
    validate();
  };

  return (<>
    <TextField label="Name" fullWidth onChange={handleNameChange} value={name} />
    <TextField label="Email" fullWidth onChange={handleEmailChange} value={email} />
    <div className={s.dropdownContainer}>
      <InputLabel id="role-dropdown-label">Role</InputLabel>
      <Select
        labelId="role-dropdown-label"
        value={role}
        onChange={handleRoleChange}
        fullWidth
      >
        <MenuItem value={'Admin'}>Admin</MenuItem>
        <MenuItem value={'Viewer'}>Viewer</MenuItem>
        <MenuItem value={'Member'}>Member</MenuItem>
      </Select>
    </div>
    {role && PERMISSIONS[role].map(permission => (
      <div className={s.employeePermissionContainer}>
        <CheckIcon/> <span>{permission}</span>
      </div>
    ))}
  </>);
};

export default ModalNewEmployee;