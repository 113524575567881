import React, { useState } from 'react';
// Global Components
import Logo from '../../components/Logo';
import Notification from '../../components/Notification';
// Assets
import GIcon from '../../assets/icons/google-icon.svg';
import landingBackgroundImg from '../../assets/landing-bkg.png';
import { MenuItem, Select } from '@material-ui/core';
// Uitls
import { loginUser } from '../../utils/mock-auth';
// Styles
import s from './landing.module.scss';

const Landing = () => {
  const [mockSignin, setMockSignin] = useState('admin');
  const [displayError, setDisplayError] = useState(false);

  const handleSigininChange = (event) => {
    setMockSignin(event.target.value);
  };
  const login = () => {
    if (mockSignin === 'error') {
      setDisplayError(true);
    } else {
      loginUser(mockSignin);
      window.location.reload();
    }
  };

  // TODO: remove mock sign in
  return (
    <div className={s.landingPage}>
      <div className={s.logoContainer}>
        <Logo />
      </div>
      <div className={s.landingBody} >
        <div className={s.headingContainer}>
          <div className={s.headingContent}>
            <h1 className={s.headingTitle}>Welcome to Theragun admin</h1>
            <p className={s.headingDescription}>World-Leading Percussive Therapy Massage Devices</p>
            <div className={s.signinDropdown}>
              <Select
                value={mockSignin}
                onChange={handleSigininChange}
              >
                <MenuItem value={'admin'}>Mock Admin</MenuItem>
                <MenuItem value={'member'}>Mock Member</MenuItem>
                <MenuItem value={'viewer'}>Mock Viewer</MenuItem>
                <MenuItem value={'unauthorized'}>Mock Unauthorized</MenuItem>
                <MenuItem value={'error'}>Mock Error</MenuItem>
              </Select>
            </div>
            <button className={s.googleLoginBtn} onClick={login}>
              <img src={GIcon} alt="device" />
              <span>Sign in with Google</span>
            </button>
          </div>
        </div>
        <div className={s.landingImageContainer} >
          <img src={landingBackgroundImg} alt="device" className={s.landingImage} />
        </div>
      </div>
      <Notification
        show={displayError}
        message="Something went wrong. Please try again."
        type="warning"
        onClose={() => setDisplayError(false)}
      />
    </div>
  );
}

export default Landing;