import { useState } from 'react';
import { TextField } from '@material-ui/core';

// TODO: Add validation
const ModalEditUser = ({
  user = { name: '', email: '', accountCreated: '', accountUpdated: '' },
}) => {
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);

  const handleNameChange = (e) => {
    setName(e.target.value);
    user.name = e.target.value;
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    user.email = e.target.value;
  };

  return (<>
    <TextField
      required
      fullWidth
      autoFocus
      value={name}
      onChange={handleNameChange}
      label="Name"
    />
    <TextField
      required
      fullWidth
      value={email}
      onChange={handleEmailChange}
      label="Email"
    />
    <TextField
      disabled
      fullWidth
      value={user.accountCreated}
      label="Date Created"
    />
    <TextField
      disabled
      fullWidth
      value={user.accountUpdated}
      label="Date Updated"
    />
  </>);
};

export default ModalEditUser;